import React, { useEffect } from "react";
import Header from "../../Components/Header/Header"; // Assuming the Header component is already created
import classes from "./Services.module.css";
import ServiceCard from "./ServiceCard";
import Footer from "../../Components/Footer/Footer";
import grassGolfBall from "../../Images/grassGolfBall.png";
import KeyMembers from "./keyMembers";
import golf1 from "../../Images/golf-hole 1.png";
import golf2 from "../../Images/golf-hole 2.png";
import golfimg from "../../Images/golfimg.png";
import searchIcon from "../../Images/search.png";
import downArrow from "../../Images/chevron-down.png";

const MainContent = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const services = [
    {
      image: golfimg,
      title: "Greenkeeping & Agronomy Expertise",
      description:
        "Our professional greenkeeping teams use the latest agronomic practices to maintain the health and playability of the course. We provide expert guidance on soil health, plant nutrition, and pest control to ensure the course remains in top condition year-round.",
    },
    {
      image: golfimg,
      title: "Course Renovation & Upgrades",
      description:
        "For existing golf courses, we offer renovation and modernization services to elevate facilities to international standards. From redesigning holes to upgrading drainage systems and cart paths, we ensure courses remain competitive and attractive to members.",
    },
    {
      image: golfimg,
      title: "Turf Management & Care",
      description:
        "Our team specializes in turf management, ensuring that greens, fairways, and rough areas are maintained to championship standards. This includes regular mowing, fertilization, aeration, and pest control to keep the grass healthy and vibrant.",
    },
    {
      image: golfimg,
      title: "Irrigation & Water Management",
      description:
        "We use advanced irrigation systems and water conservation strategies to maintain lush courses while minimizing water usage. With the help of sensors and AI-driven irrigation plans, water is applied efficiently to preserve the course's health while being eco-friendly.",
    },
    {
      image: golfimg,
      title: "Landscaping & Ecological Preservation:",
      description:
        "PGA Bharat is committed to sustainability and ecological balance. Our landscaping services not only enhance the course's aesthetic appeal but also protect local ecosystems by using native plants and promoting biodiversity.",
    },
    {
      image: golfimg,
      title: "Sustainable Practices:",
      description:
        "PGA Bharat incorporates sustainability into every aspect of course maintenance. This includes using organic fertilizers, eco-friendly pest control methods, and energy-efficient equipment to reduce the environmental impact of operations.",
    },
  ];

  return (
    <div>
      <Header />
      {window.innerWidth <= 768 ? (
        <div className={classes.searchbar}>
          <img
            src={searchIcon}
            alt="Search Icon"
            className={classes.searchIcon}
          />
          <input
            type="text"
            placeholder="Search here"
            className={classes.searchInput}
          />
          <button className={classes.exploreButton}>
            Explore
            <img
              src={downArrow}
              alt="Down Arrow"
              className={classes.downArrowIcon}
            />
          </button>
        </div>
      ) : (
        <></>
      )}
      {/* Main Banner */}
      <div
        className={classes.mediaNewsSection}
        style={{ backgroundImage: `url(${grassGolfBall})` }}
      >
        <div className={classes.posterText}>
          <div className={classes.heading}>Services Offered by PGA Bharat</div>
        </div>
      </div>

      {/* Services Section */}
      <div className={classes.servicesGrid}>
        {services.map((service, index) => (
          <div key={index} className={classes.serviceCard}>
            <div
              className={classes.imageContainer}
              style={{ backgroundColor: "black" }}
            >
              <img
                src={service.image}
                alt={service.title}
                className={classes.serviceImage}
              />
            </div>
            <div className={classes.servicetitle}>{service.title}</div>
            <div className={classes.servicedesc}>{service.description}</div>
          </div>
        ))}
      </div>

      <KeyMembers />

      <div className={classes.viewerdiv}>
        <div>
          <div className={classes.bignumber}>2,00,000</div>
          <div className={classes.bignumbertext}>Visitors</div>
        </div>
        <div>
          <div className={classes.bignumber}>8 Million</div>
          <div className={classes.bignumbertext}>Viewers</div>
        </div>
        <div>
          <div className={classes.bignumber}>265 Million USD</div>
          <div className={classes.bignumbertext}>Estimated Revenue</div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default MainContent;
