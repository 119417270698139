// src/PGA.js
import React, { useEffect } from "react";
import classes from "./partnerships.module.css";
import line_img from "../../Images/image 23.png";
import person from "../../Images/image 22.png";
import hand from "../../Images/image 24.png";
import member from "../../Images/image 21.png";
import image20 from "../../Images/image 20.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import searchIcon from "../../Images/search.png";
import downArrow from "../../Images/chevron-down.png";
import TIM from "../../Images/Tim Lobb 1.png";
import Colin from "../../Images/Colin Montgomerie.png";
import shapporjiimage from "../../Images/shapoorjiimage.png";
import stonecraftimage from "../../Images/stonecraftimage.png";
import shapporjidivimage from "../../Images/shapoorjidivimage.png";
import stonecraftdivimage from "../../Images/stonecraftdivimage.png";
import workers from "../../Images/workers.png";
import tvastar from "../../Images/tvastar.png";

const Partnerships = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  return (
    <div className={classes.container}>
      <Header />
      {window.innerWidth <= 768 ? (
        <div className={classes.searchbar}>
          <img
            src={searchIcon}
            alt="Search Icon"
            className={classes.searchIcon}
          />
          <input
            type="text"
            placeholder="Search here"
            className={classes.searchInput}
          />
          <button className={classes.exploreButton}>
            Explore
            <img
              src={downArrow}
              alt="Down Arrow"
              className={classes.downArrowIcon}
            />
          </button>
        </div>
      ) : (
        <></>
      )}
      <div
        className={classes.divStyle}
        // style={{ backgroundImage: `url(${image20})` }}
      >
        <div className={classes.head1}>Partners</div>
        <br />
        <div className={classes.head2}>
          At PGA Bharat, our success is built on strong collaborations with
          leading organizations and industry experts. Our strategic partnerships
          drive innovation, enhance our golfing experiences, and expand our
          reach in the world of golf.
        </div>
      </div>

      <div className="page2-partners">
        <div className="page2-partners-heading">Design Partners</div>
        <div className="Page2-partners-container">
          <div className="Page2-partners-container-TIM-LOBB">
            <div className="Page2-partners-TIM-LOBB-heading">TIM LOBB</div>
            <div className="Page2-partners-TIM-LOBB-info">
              <img
                className="Page2-partners-TIM-LOBB-info-left"
                src={TIM}
              ></img>
              <div className="Page2-partners-TIM-LOBB-info-right">
                A renowned global golf course architecture and design firm, Lob
                & Partners has an extensive portfolio of award-winning golf
                courses worldwide. Their expertise in integrating natural
                landscapes into course designs creates a seamless blend of
                challenge and beauty, ensuring that every course provides a
                world-class golfing experience.
              </div>
            </div>
            <div
              className="Page2-partners-TIM-LOBB-learn-more"
              onClick={() =>
                window.open("https://lobbandpartners.com/our-team/", "_blank")
              }
            >
              Learn More
            </div>
          </div>

          <div className="Page2-partners-container-colin">
            <div className="Page2-partners-colin-heading">
              Colin Montgomerie
            </div>
            <div className="Page2-partners-colin-info">
              <img className="Page2-partners-colin-info-left" src={Colin}></img>
              <div className="Page2-partners-colin-info-right">
                The legendary Scottish golfer and one of the most successful
                European Tour players, Colin Montgomerie has transitioned his
                golfing prowess into course design. Montgomerie brings a
                player’s insight into his designs, focusing on playability,
                strategy, and elegance. His involvement ensures that PGA
                Bharat’s courses offer an unforgettable experience for golfers
                of all skill levels.
              </div>
            </div>
            <div
              className="Page2-partners-colin-learn-more"
              onClick={() =>
                window.open(
                  "https://www.colinmontgomerie.com/profile/ ",
                  "_blank"
                )
              }
            >
              Learn More
            </div>
          </div>
        </div>
      </div>

      <div className={classes.devpattext}>Development Partners</div>

      <div className={classes.devpatdiv}>
        <div className={classes.devpatitems}>
          <div className={classes.shapporjiimage}>
            <img src={shapporjiimage} />
          </div>
          <div className={classes.shapporjidivimage}>
            <img
              src={shapporjidivimage}
              className={classes.shapporjidivimage1}
            />
          </div>
          <div className={classes.shapoorjitext}>
            Shapoorji Pallonji Real Estate is a renowned name in the real estate
            industry, known for its commitment to delivering high-quality
            projects across India. With decades of experience, the company has
            built a strong reputation for innovative design, sustainable
            construction practices, and a focus on creating modern living and
            commercial spaces.
          </div>
          <div></div>
        </div>
        <div className={classes.devpatitems}>
          <div className={classes.shapporjiimage}>
            <img src={stonecraftimage} />
          </div>
          <div className={classes.shapporjidivimage}>
            <img
              src={stonecraftdivimage}
              className={classes.shapporjidivimage1}
            />
          </div>
          <div className={classes.shapoorjitext}>
            Stonecraft Group is a multidisciplinary real estate firm based in
            Hyderabad, dedicated to developing eco-conscious projects. Over the
            past 17 years, the company has successfully sold over 1 million
            square yards of real estate. With a commitment to sustainability,
            Stonecraft builds homes designed to last for generations, utilizing
            natural resources and durable materials.
          </div>
          <div></div>
        </div>
        <div className={classes.devpatitems}>
          <div className={classes.shapporjiimage}>
            <img src={tvastar} />
          </div>
          <div className={classes.shapporjidivimage}>
            <img src={workers} className={classes.shapporjidivimage1} />
          </div>
          <div className={classes.shapoorjitext}>
            Tvastar Infratech Pvt. Ltd., founded in 2012, is a premier
            construction company specializing in providing innovative and
            reliable solutions for geotechnical, transportation, hydraulic, and
            environmental challenges. With expertise in EPC
            (Engineering-Procurement-Construction) projects, Tvastar focuses on
            delivering high-quality infrastructure solutions while ensuring
            safety, efficiency, and cost-effectiveness.
          </div>
          <div></div>
        </div>
      </div>

      {/* <div className={classes.lineimg}>
        <img src={line_img} alt=" Model" className={classes.line_img} />
      </div>
      <div className={classes.sec}>
        <div className={classes.sec1}>
          <div className={classes.person}>
            <img src={person} alt="M" className={classes.person_img} />
          </div>
          <div className={classes.text}>
            <div className={classes.textcontent}>
              The PGA of America announced today a new partnership with Aivot
              Golf & Sports Management Private Limited, a new entity focused on
              land acquisition, recreational sports management, and the creation
              of hotel and residential developments across India.
            </div>
            <div className={classes.textcontent}>
              Established in 2023, our mission is to grow and elevate the game
              of golf across India. By combining the rich legacy and expertise
              of the PGA of America with AIVOT’s cutting-edge technology, PGA
              Bharat aims to create a world-class golfing ecosystem in India,
              making golf accessible, exciting, and inclusive for all.
            </div>
            <div className={classes.textcontent}>
              Under the partnership, PGA of America and Aivot will work to make
              a meaningful impact on the Indian golfing community. This
              collaborative effort will focus on developing new and existing
              golf infrastructure, providing unparalleled opportunities for
              aspiring golfers and promoting the sport’s accessibility and
              inclusivity.
            </div>
            <div className={classes.textcontent}>
              Initial developments are scheduled with multiple PGA of
              America-branded golf facilities located throughout India. These
              facilities will provide a mix of golf courses, academies and
              training centers, offering golfers the chance to enhance their
              skills and learn from top-tier PGA Professionals.
            </div>
            <div className={classes.textcontent}>
              Comprehensive programming designed by the PGA of America and Aivot
              will cater to golfers of all ages, while offering progressive
              learning programs for all skill levels. As part of the
              partnership, local Indian golf professionals working within the
              PGA of America Golf Academies network will have the opportunity to
              embark on a rewarding journey towards PGA of America Class A
              Membership, enriching their careers in the process.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.midtext}>
        The partnership between the PGA of America and Aivot Golf & Sports
        Management Private Limited is poised to make a significant impact on
        golfing infrastructure and development throughout India.
        <br /> As the partnership progresses, multiple PGA of America Golf
        Academies will be established across the country, providing golfers
        unprecedented access to world-class training facilities and expert
        instruction.
      </div>

      <div className={classes.hand}>
        <img src={hand} alt="M" className={classes.handshake} />
      </div>
      <div className={classes.member}>
        <img src={member} alt="M" className={classes.memberimg} />
      </div> */}
      <Footer />
    </div>
  );
};

export default Partnerships;
